import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CareerContent from './CareerContent';


function Careers() {
  return (
    <>
            <Header
                parentMenu="contact"
                headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
            />
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <CareerContent />
                    <Footer />
                </div>
            </div>
                    
        </>
  )
}

export default Careers