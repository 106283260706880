import React from 'react';
import SingleService from '../../components/Service';
import Breadcrumb from '../../components/Breadcrumb';
import CTA from '../../components/CTA';

import bgImg from '../../assets/img/cta/cta-bg-2.png';
import serviceIconBg1 from '../../assets/img/service/sv-icon-shape-13.png'
import serviceIconBg2 from '../../assets/img/service/sv-icon-shape-14.png'
import serviceIconBg3 from '../../assets/img/service/sv-icon-shape-15.png'
import serviceIconBg4 from '../../assets/img/service/sv-icon-shape-16.png'
import serviceIconBg5 from '../../assets/img/service/sv-icon-shape-17.png'
import serviceIconBg6 from '../../assets/img/service/sv-icon-shape-18.png'
import serviceIconBg7 from '../../assets/img/service/sv-icon-shape-19.png'
import serviceIconBg8 from '../../assets/img/service/sv-icon-shape-20.png'
import serviceIconBg9 from '../../assets/img/service/sv-icon-shape-21.png'
import serviceIconBg10 from '../../assets/img/service/sv-icon-shape-24.png'
import serviceIconBg11 from '../../assets/img/service/sv-icon-shape-22.png'
import serviceIconBg12 from '../../assets/img/service/sv-icon-shape-23.png'

const ServiceMain = () => {
	return (
		<main>

			<Breadcrumb
				pageTitle= "Service One"
			/>
			
			<div className="service__area service__plr mt-100 mb-20 p-relative">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass= "service__item service__item-bg p-relative fix shape-none"
								btnClass= "service__link service__link-color-1"
								titleClass= "service__title color-1"
								descClass= "text-white"
								serviceIcon= "fal fa-bullseye"
								serviceIconBg= {serviceIconBg1}
								Title= "Brand Discovery & Strategy"
								Description= "Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
								btnText= "Read More"
							>
								<ul>
									<li>- Strategy and Positioning</li>
									<li>- Brand Identity & Collaterals</li>
									<li>- Brand Architecture</li>
									<li>- Trends & Insights</li>
								</ul>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass= "service__item service__item-bg p-relative fix shape-none"
								btnClass= "service__link service__link-color-2"
								titleClass= "service__title color-2"
								descClass= "text-white"
								serviceIcon= "fal fa-handshake-slash"
								serviceIconBg= {serviceIconBg2}
								Title= "Social Media Strategy"
								Description= "Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
								btnText= "Read More"
							>
								<ul>
									<li>- Social Content Strategy</li>
									<li>- Social Listening</li>
									<li>- Social Media Audit & Analytics</li>
								</ul>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass= "service__item service__item-bg p-relative fix shape-none"
								btnClass= "service__link service__link-color-3"
								titleClass= "service__title color-3"
								descClass= "text-white"
								serviceIcon= "fal fa-database"
								serviceIconBg= {serviceIconBg3}
								Title= "Data and Insights"
								Description= "Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
								btnText= "Read More"
							>
								<ul>
									<li>- Data Mining</li>
									<li>- Post campaign evaluations</li>
									<li>- Deep Listening</li>
								</ul>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass= "service__item service__item-bg p-relative fix shape-none"
								btnClass= "service__link service__link-color-4"
								titleClass= "service__title color-4"
								descClass= "text-white"
								serviceIcon= "fal fa-lightbulb-on"
								serviceIconBg= {serviceIconBg4}
								Title= "Creative and Content"
								Description= "Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
								btnText= "Read More"
							>
								<ul>
									<li>- Content Strategy</li>
									<li>- Content Audit</li>
									<li>- Branded Content</li>
									<li>- Videography</li>
								</ul>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass= "service__item service__item-bg p-relative fix shape-none"
								btnClass= "service__link service__link-color-5"
								titleClass= "service__title color-5"
								descClass= "text-white"
								serviceIcon= "fas fa-envelope"
								serviceIconBg= {serviceIconBg5}
								Title= "Email Marketing Automation"
								Description= "Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
								btnText= "Read More"
							>
								<ul>
									<li>- Content Writing & Design</li>
									<li>- HTML Emailers</li>
									<li>- Setup and Schedule</li>
									<li>- Reporting</li>
								</ul>
							</SingleService>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass= "service__item service__item-bg p-relative fix shape-none"
								btnClass= "service__link service__link-color-6"
								titleClass= "service__title color-6"
								descClass= "text-white"
								serviceIcon= "fal fa-code"
								serviceIconBg= {serviceIconBg6}
								Title= "Design and Development"
								Description= "Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
								btnText= "Read More"
							>
								<ul>
									<li>- User Interface Design</li>
									<li>- Website Design & CMS Development</li>
									<li>- Creative assets</li>
									<li>- Native Mobile Applications</li>
									<li>- E-commerce Development & Integration</li>
								</ul>
							</SingleService>
						</div> 
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass= "service__item service__item-bg p-relative fix shape-none"
								btnClass= "service__link service__link-color-7"
								titleClass= "service__title color-7"
								descClass= "text-white"
								serviceIcon= "fas fa-cog"
								serviceIconBg= {serviceIconBg7}
								Title= "Search Engine Optimization"
								Description= "Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
								btnText= "Read More"
							>
								<ul>
									<li>- Link Building Services</li>
									<li>- Local SEO Services</li>
									<li>- eCommerce SEO Services</li>
									<li>- GA consulting</li>
								</ul>
							</SingleService>
						</div>
						{/* <div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass= "service__item service__item-bg p-relative fix shape-none"
								btnClass= "service__link service__link-color-8"
								titleClass= "service__title color-8"
								descClass= "text-white"
								serviceIcon= "fas fa-wifi"
								serviceIconBg= {serviceIconBg8}
								Title= "Paid Media Services"
								Description= "Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
								btnText= "Read More"
							>
								<p>Business building it before the tab providet management, Payroll & Worksite Services full-fledged.</p>
							</SingleService>
						</div> */}
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass= "service__item service__item-bg p-relative fix shape-none"
								btnClass= "service__link service__link-color-9"
								titleClass= "service__title color-9"
								descClass= "text-white"
								serviceIcon= "fad fa-chess-clock-alt"
								serviceIconBg= {serviceIconBg9}
								Title= "White Label Services"
								Description= "Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
								btnText= "Read More"
							>
								<ul>
									<li>- White Label PPC Services</li>
									<li>- White Label SEO Services</li>
									<li>- White Label Web Development Services</li>	
								</ul>
							</SingleService>
						</div>        
						<div className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
							<SingleService
								itemClass= "service__item service__item-bg p-relative fix shape-none"
								btnClass= "service__link service__link-color-10"
								titleClass= "service__title color-10"
								descClass= "text-white"
								serviceIcon= "fad fa-users"
								serviceIconBg= {serviceIconBg10}
								Title= "Dedicated Resources"
								Description= "Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
								btnText= "Read More"
							>
								<ul>
									<li>- Hire SEO Expert</li>
									<li>- Hire SEM Expert</li>
									<li>- Hire Shopify Developers</li>
									<li>- Hire WordPress Developers</li>	
									<li>- Hire PHP Developers</li>	
									<li>- ReactJS Developers</li>	
									<li>- Hire UX/UI Designers</li>	
								</ul>
							</SingleService>
						</div>
						
					</div>
				</div>
			</div>
			
			<CTA
				ctaBG={bgImg}
			/>

		</main>
	);
}

export default ServiceMain;